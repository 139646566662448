import React, { useState, useEffect } from "react";
import Seo from "../../components/Seo";
import { useInView } from "react-intersection-observer";
import NavBackBtn from "../../components/UI/navBackBtn";
import Layout from "../../components/layouts/index";
import RosaVideo from "../../assets/videos/rosa-olof_dp_lvl3.mp4";

const RosaOlof = () => {
  const [isItemInView, setisItemInView] = useState(false);

  // Intersection observer that set's the inView state when 15% of the referenced element is hidden/insight.

  const { ref, inView } = useInView({
    threshold: 0,
    // delay: 100,
  });

  // Handle triggering animations.

  useEffect(() => {
    if (inView) {
      setisItemInView(true);
    }
  }, [isItemInView, inView, setisItemInView]);

  return (
    <Layout>
      <Seo title="Rósa Ólöf" />
      <article className="single-project rosa-olof-project">
        <div className="single-project-text">
          <div className="heading-box">
            <NavBackBtn url={"projects/"} label={"BACK TO PROJECTS"} />
            <h2 className="main-heading">Rósa Ólöf</h2>
            <a
              className="link"
              href="https://rosa-olof.com/"
              target="example"
              rel="noopener"
            >
              rosa-olof.com
            </a>
          </div>
          <div className="info-box">
            <div className="what-how-box">
              <div className="what-box">
                <h3 className="small-heading">WHAT</h3>
                <span className="label-small">Design</span>
                <span className="label-small">Programming</span>
              </div>
              <div className="how-box">
                <h3 className="small-heading">HOW</h3>
                <span className="label-small">Gatsby.js</span>
                <span className="label-small">Netlify</span>
                <span className="label-small">Sass</span>
                <span className="label-small">Wordpress</span>
              </div>
            </div>

            <div className="text-box">
              <p className="scndry-text">
                My challenge was to design and program a blog and portfolio
                website for the Icelandic author, Rósa Ólöf. In the design
                process, I tried to capture the personality of Rósa. I designed
                a rose, as a homage to her name and to greet visitors. And I
                used a grand serif font for headlines to give the site a classy
                feel. I built the site with Gatsby.js and used WordPress as a
                headless cms. And I used Netlify for continuous deployment. For
                a deeper dive, check out the link to the Gitlab repository.
              </p>
              <div className="under-hood-box">
                <h3 className="small-heading">UNDER THE HOOD</h3>
                <a
                  className="link-small"
                  href="https://gitlab.com/G.Ros/rosa-blogg"
                  target="example"
                  rel="noopener"
                >
                  gitlab.com/G.Ros/rosa-blogg
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="single-project-video-container rosa-olof-background">
          <video
            ref={ref}
            className={
              isItemInView
                ? "single-project-video"
                : "single-project-video-idle"
            }
            autoPlay
            loop
            muted
            playsinline
          >
            <source src={RosaVideo} type="video/mp4"></source>
            <h2 className="scndry-heading">
              Your browser does not support HTML5 video.
            </h2>
          </video>
        </div>
      </article>
    </Layout>
  );
};

export default RosaOlof;
